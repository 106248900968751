import { AppImages } from '@/app'
import { Animations, AnimationsProps } from './Animations'

const animation = AppImages.starsAnimation

export const StarsAnimation = (props: Partial<AnimationsProps> = {}) => {
  return (
    <Animations
      animation={animation}
      autoplay
      loop
      {...props}
    />
  )
}
