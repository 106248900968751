import { View, Text, Link } from '@/components'
import { StylesOf } from '@codeleap/common'
import { FooterComposition } from '../../../app/stylesheets/Footer'
import { Settings } from '@/app'

export type FooterDisclaimersProps = {
  variantStyles?: StylesOf<FooterComposition>
}

export const FooterDisclaimers = (props: FooterDisclaimersProps) => {
  const { variantStyles } = props

  return (
    <View css={variantStyles.disclaimersWrapper}>
      <Text css={variantStyles.disclaimerText} variants={['paddingBottom:1']}>
        It is important that, before and at all times using our services, you remain aware of the proportionality and suitability of using this platform, and of all the options and avenues that may be available to you aside from this platform such as the Courts and Tribunals system of England and Wales.
      </Text>
      <Text css={variantStyles.disclaimerText} variants={['paddingBottom:1']}>
        We do not currently offer mediation of certain types of disputes. For more details, see our Terms and Conditions
      </Text>
      <Text css={variantStyles.disclaimerText}>
        Hexameron Limited is registered in England & Wales, Company no.
        14336297.
      </Text>
      <Text css={variantStyles.disclaimerText}>
        By using our website you agree to the {''}
        <Link
          css={variantStyles.disclaimerText}
          to={Settings.ContactINFO.TermsAndConditions}
          target='_blank'
        >
          {' '}
          terms of use.
        </Link>
      </Text>
    </View>
  )
}
