import { View, Page, SideBarMenu, AuthenticatedScreen } from '@/components'
import { variantProvider } from '@/app'
import { PropsWithChildren } from 'react'
import { useMediaQueryDown } from '@/utils'

export type MainLayoutProps = PropsWithChildren & {
  title: string
  style?: any
  loading?: boolean
}

export const MainLayout = ({
  title,
  children,
  style,
  loading,
}: MainLayoutProps) => {
  const isMid = useMediaQueryDown('mid')

  return (
    <AuthenticatedScreen>
      <Page
        loading={loading}
        centerContent={false}
        pageTitle={title}
        showFooter={false}
        styles={{ innerWrapper: styles.page }}
      >
        <View css={[styles.wrapper, style]}>
          {!isMid ? <SideBarMenu /> : null}
          {children}
        </View>
      </Page>
    </AuthenticatedScreen>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    page: {
      ...theme.presets.fullHeight,
      ...theme.presets.flex,
    },
    wrapper: {
      ...theme.presets.fullWidth,
      ...theme.presets.fullHeight,
      ...theme.spacing.gap(10),
      ...theme.spacing.paddingTop(5),
      ...theme.spacing.paddingHorizontal(8),
      margin: '0 auto',
      maxWidth: 1408,
      [theme.media.down('large')]: {
        ...theme.spacing.gap(0),
        ...theme.spacing.margin(0),
      },
      [theme.media.down('mid')]: {
        ...theme.spacing.paddingHorizontal(4),
      },
      [theme.media.down('midish')]: {
        ...theme.spacing.padding(0),
      },
    },
  }),
  true
)
