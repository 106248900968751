import { Settings, variantProvider } from '@/app'
import { IconPlaceholder } from '@codeleap/common'
import * as LibComponents from '@codeleap/web'
import { defaultStyles } from '@codeleap/web'
import * as StyleSheets from '../app/stylesheets'
import { ActivityIndicatorCP } from './ActivityIndicator'
export * from './Widgets'
export * from './ComponentShowCase'

export const variants = {
  ...defaultStyles,
  Alert: StyleSheets.AppAlertStyles,
  Checkbox: StyleSheets.AppCheckboxStyles,
  RadioInput: StyleSheets.AppRadioInputStyles,
  Touchable: StyleSheets.AppTouchableStyles,
  Button: StyleSheets.AppButtonStyles,
  Icon: StyleSheets.AppIconStyles,
  Footer: StyleSheets.AppFooterStyles,
  ActivityIndicator: StyleSheets.AppActivityIndicatorStyles,
  Select: StyleSheets.AppSelectStyles,
  DisputeSelectStatus: StyleSheets.DisputeSelectStatusStyles,
  Text: StyleSheets.AppTextStyles,
  View: StyleSheets.AppViewStyles,
  Modal: StyleSheets.AppModalStyles,
  Drawer: StyleSheets.AppDrawerStyles,
  TextInput: StyleSheets.AppTextInputStyles,
  Image: StyleSheets.AppImageStyles,
  Link: StyleSheets.LinkStyles,
  Logo: StyleSheets.LogoStyles,
  Page: StyleSheets.PageStyles,
  Overlay: StyleSheets.AppOverlayStyles,
  List: StyleSheets.AppListStyles,
  Avatar: StyleSheets.AvatarStyles,
  CenterWrapper: StyleSheets.CenterWrapperStyles,
  ActionIcon: StyleSheets.AppActionIconStyles,
  LoadingOverlay: StyleSheets.AppLoadingOverlayStyles,
  InputBase: StyleSheets.AppInputBaseStyles,
  Switch: StyleSheets.AppSwitchStyles,
  Slider: StyleSheets.AppSliderStyles,
  NumberIncrement: StyleSheets.AppNumberIncrementStyles,
  Tooltip: StyleSheets.AppTooltipStyles,
  Badge: StyleSheets.AppBadgeStyles,
  SegmentedControl: StyleSheets.AppSegmentedControlStyles,
  Pager: StyleSheets.AppPagerStyles,
  CardBase: StyleSheets.AppCardBaseStyles,
  Navigation: StyleSheets.NavigationStyles,
  EmptyPlaceholder: StyleSheets.AppEmptyPlaceholderStyles,
  Grid: StyleSheets.AppGridStyles,
  CropPicker: StyleSheets.AppCropPickerStyles,
  Dropzone: StyleSheets.DropzoneStyles,
  Tag: StyleSheets.TagStyles,
  CardPricing: StyleSheets.CardPricingStyles,
  DisputeItem: StyleSheets.DisputeItemStyles,
  Thread: StyleSheets.ThreadStyles,
  ParticipantItem: StyleSheets.ParticipantItemStyles,
  SettlementItem: StyleSheets.SettlementItemStyles,
  SettlementList: StyleSheets.SettlementListStyles,
  Placeholder: StyleSheets.PlaceholderStyles,
  DisputeType: StyleSheets.DisputeTypeStyles,
  OptionsDropDown: StyleSheets.OptionsDropDownStyles,
  SideBarMenu: StyleSheets.SideBarMenuStyles,
  DisputeItemOptions: StyleSheets.DisputeItemOptionsStyles,
  Chat: StyleSheets.ChatStyles,
  DisputeInfo: StyleSheets.DisputeInfoStyles,
  ChatMessage: StyleSheets.ChatMessageStyles,
  UploadSettlement: StyleSheets.UploadSettlementStyles,
  ProgressBar: StyleSheets.AppProgressBarStyles,
  ProgressCircle: StyleSheets.AppProgressCircleStyles,
  ImageModal: StyleSheets.ImageModalStyles,
  NavigationLink: StyleSheets.NavigationLinkStyles,
  PageSection: StyleSheets.PageSectionStyles,
}

LibComponents.Select.defaultProps.arrowIconName = 'chevrons-up-down' as IconPlaceholder
LibComponents.Select.defaultProps.selectedIcon = 'check' as IconPlaceholder
LibComponents.Select.defaultProps.clearable = true
LibComponents.Select.defaultProps.components = { ClearIndicator: null }
LibComponents.TextInput.defaultProps.visibleIcon = 'eye' as IconPlaceholder
LibComponents.TextInput.defaultProps.hiddenIcon = 'eye-off' as IconPlaceholder
LibComponents.SearchInput.defaultProps.searchIcon = 'search' as IconPlaceholder
LibComponents.SearchInput.defaultProps.clearIcon = 'x' as IconPlaceholder
LibComponents.Modal.defaultProps.closeIconName = 'x' as IconPlaceholder
LibComponents.ActivityIndicator.defaultProps.component = ActivityIndicatorCP as () => JSX.Element
LibComponents.List.defaultProps.refresh = false
LibComponents.List.defaultProps.overscan = 7
LibComponents.Grid.defaultProps.refresh = false
LibComponents.Grid.defaultProps.overscan = 7
LibComponents.CropPicker.defaultProps = {
  targetCrop: {
    aspect: Settings.Images.Aspect.default,
    ruleOfThirds: true,
  },
}

const components = variantProvider.typeComponents({
  View: [LibComponents.View, variants.View],
  Dropzone: [LibComponents.Dropzone, variants.Dropzone],
  Icon: [LibComponents.Icon, variants.Icon],
  Text: [LibComponents.Text, variants.Text],
  Touchable: [LibComponents.Touchable, variants.Touchable],
  Scroll: [LibComponents.Scroll, variants.Scroll],
  ActivityIndicator: [
    LibComponents.ActivityIndicator,
    variants.ActivityIndicator,
  ],
  Button: [LibComponents.Button, variants.Button],
  ActionIcon: [LibComponents.ActionIcon, variants.ActionIcon],
  Modal: [LibComponents.Modal, variants.Modal],
  Checkbox: [LibComponents.Checkbox, variants.Checkbox],
  RadioInput: [LibComponents.RadioGroup, variants.RadioInput],
  SegmentedControl: [LibComponents.SegmentedControl, variants.SegmentedControl],
  Select: [LibComponents.Select, variants.Select],
  TextInput: [LibComponents.TextInput, variants.TextInput],
  Overlay: [LibComponents.Overlay, variants.Overlay],
  FileInput: [LibComponents.FileInput, {}],
  List: [LibComponents.List, variants.List],
  Drawer: [LibComponents.Drawer, variants.Drawer],
  LoadingOverlay: [LibComponents.LoadingOverlay, variants.LoadingOverlay],
  InputBase: [LibComponents.InputBase, variants.InputBase],
  Switch: [LibComponents.Switch, variants.Switch],
  Slider: [LibComponents.Slider, variants.Slider],
  NumberIncrement: [LibComponents.NumberIncrement, variants.NumberIncrement],
  Tooltip: [LibComponents.Tooltip, variants.Tooltip],
  Badge: [LibComponents.Badge, variants.Badge],
  Pager: [LibComponents.Pager, variants.Pager],
  EmptyPlaceholder: [LibComponents.EmptyPlaceholder, variants.EmptyPlaceholder],
  Grid: [LibComponents.Grid, variants.Grid],
  CropPicker: [LibComponents.CropPicker, variants.CropPicker],
  SearchInput: [LibComponents.SearchInput, variants.TextInput],
  Tag: [LibComponents.Tag, variants.Tag],
  ProgressBar: [LibComponents.ProgressBar, variants.ProgressBar],
  ProgressCircle: [LibComponents.ProgressCircle, variants.ProgressCircle],
})

const SelectWithGenerics = LibComponents.Select as <
  T extends string | number = string,
  Multi extends boolean = false
>(
  props: LibComponents.SelectProps<T, Multi>
) => JSX.Element

export const allComponents = {
  ...components,
  Select: SelectWithGenerics,
}

export const {
  View,
  Button,
  Text,
  Icon,
  Touchable,
  ActionIcon,
  Scroll,
  ActivityIndicator,
  Modal,
  Checkbox,
  RadioInput,
  Select,
  TextInput,
  Overlay,
  LoadingOverlay,
  FileInput,
  List,
  Drawer,
  Switch,
  NumberIncrement,
  Tooltip,
  SegmentedControl,
  Pager,
  Grid,
  Dropzone,
  SearchInput,
  Badge,
  CropPicker,
  Tag,
} = allComponents

export * from './Modals'
export * from './AppStatus'
export * from './CenterWrapper'
export * from './Header'
export * from './Avatar'
export * from './Footer'
export * from './Layouts'
export * from './Image'
export * from './Link'
export * from './Logo'
export * from './GlobalStyle'
export * from './Animations'
export * from './ActivityIndicator'
export * from './Modals'
export * from './Cards'
export * from './SEO'
export * from './Navigation'
export * from './LanguageSelector'
export * from './Auth'
export * from './Disputes'
export * from './Pricing'
export * from './Disputes'
export * from './Chat'
export * from './Participants'
export * from './Settlements'
export * from './Placeholder'
export * from './Disputes'
export * from './OptionsDropDown'
export * from './SideBarMenu'
export * from './FileButton'
export * from './Homepage'
export * from './FormPage'
export * from './Paywall'
export * from './Onboarding'
export * from './NavigationLink'
export * from './Forms'
export * from './TermsCheckboxes'
export * from './ContentCard'
export * from './PDFViewer'
export * from './OTP'
export * from './StarsAnimation'

export default components

