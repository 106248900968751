import {
  MainLayout,
  MainLayoutProps,
  DisputeInfo,
  View,
  OSAlert,
  Redirect,
} from '@/components'
import { cloneElement, JSX, PropsWithChildren } from 'react'
import { Dispute } from '@/types'
import { APIClient } from '@/services'
import { useMediaQueryDown } from '@/utils'

const body = `You currently don't have a mediator assigned for this case. Please wait for a mediator to be allocated before accessing this page.`

type DisputeLayoutProps = MainLayoutProps &
  PropsWithChildren & {
    id: Dispute['id']
    blockIfHasNoMediator?: boolean
  }

export const DisputeLayout = ({
  id,
  children,
  blockIfHasNoMediator,
  ...props
}: DisputeLayoutProps) => {
  const isMidsh = useMediaQueryDown('midish')

  const { items: threads } = APIClient.Threads.threadsManager.useList({
    filter: { dispute: id },
    queryOptions: { refetchInterval: 10000 },
  })
  const { data: dispute } = APIClient.Disputes.disputesManager.useRetrieve({
    id,
    queryOptions: { refetchInterval: 10000 * 60 }, //10 minutes
  })

  const isLoaded = threads?.length > 0 && !!dispute

  if (!!dispute && blockIfHasNoMediator && !dispute?.mediator) {
    OSAlert.error({ title: '', body })

    return <Redirect to={`/disputes/${id}/case_details`} />
  }

  return (
    <MainLayout loading={!isLoaded} {...props}>
      <View variants={['fullWidth']} responsiveVariants={{ small: ['block'] }}>
        {(!isMidsh || !children) && (
          <DisputeInfo dispute={dispute} threads={threads} />
        )}
        {!!children &&
          cloneElement(children as JSX.Element, { dispute, threads })}
      </View>
    </MainLayout>
  )
}
