import { AppForms, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { onUpdate, useForm, useI18N, useState } from '@codeleap/common'
import { Button, Text, TextInput, View, AuthFormComponents } from '@/components'
import { Navigation, useOTP } from '@/utils'
import { useKeydown } from '@codeleap/web'
import { AppStatus, useAppSelector } from '@/redux'

export const LoginForm = () => {
  const form = useForm(AppForms.login, {})
  const { t } = useI18N()

  const [verificationStatus, setVerificationStatus] = useState<
    'verified' | 'denied' | 'idle'
  >('idle')

  const { profileQuery, logout } = APIClient.Session.useSession()
  const login = APIClient.Session.useLogin()

  const { creatingDispute } = useAppSelector(store => store.Dispute)

  const { sendCode } = useOTP({
    email: form.values.email,
    onSuccess: () => setVerificationStatus('verified'),
    onError: () => setVerificationStatus('denied'),
  })

  const handleSubmit = async () => {
    try {
      const successLogin = await login({
        ...form.values,
        email: form?.values?.email?.toLowerCase(),
      })
      if (successLogin) {
        sendCode()
      }
    } catch (e) {
      logger.error(e)
    }
  }

  onUpdate(() => {
    if (verificationStatus === 'verified') {
      profileQuery.refetch().then(() => {
        if (creatingDispute) {
          // @ts-ignore
          Navigation.navigate('Disputes.CreateDispute', {
            params: { page: 'disputeValue' },
          })
        } else Navigation.navigate('Disputes.List')
      })
    } else if (verificationStatus === 'denied') {
      logout()
    }
  }, [verificationStatus, creatingDispute])

  useKeydown(
    'Enter',
    () => {
      const isInvalidSubmit = !form.isValid
      if (isInvalidSubmit) return
      handleSubmit()
    },
    [form?.values]
  )

  return (
    <View variants={['column', 'fullWidth']}>
      <Text
        variants={['h1', 'extraBold', 'marginBottom:1']}
        responsiveVariants={{ small: ['h2'] }}
        text={t('Login.title')}
      />
      <Text variants={['p1', 'marginBottom:4']} text={t('Login.subtitle')} />

      <View variants={['flex', 'column', 'gap:4', 'fullWidth']}>
        <View variants={['column', 'fullWidth']}>
          <TextInput {...form.register('email')} />
          <TextInput {...form.register('password')} visibilityToggle />
        </View>

        <View variants={['column', 'gap:2']}>
          <Button
            onPress={handleSubmit}
            disabled={!form.isValid}
            debugName={`submit Login`}
            text={t('actions.continue')}
            variants={[
              'marginHorizontal:auto',
              'fullWidth',
              'large',
              !form.isValid && 'outline',
            ]}
            style={styles.submitBtn}
          />

          <AuthFormComponents.RedirectLink
            route={'Auth.Signup'}
            text={`${t('Login.txt1')}`}
            textAction={`${t('actions.signup')}`}
          />

          <Button
            onPress={() => AppStatus.setModal('forgotBefore')}
            debugName={'navigate to forgot password Button'}
            variants={[
              'marginHorizontal:auto',
              'fullWidth',
              'minimal',
              'large',
              'row',
              'gap:1',
              'alignCenter',
              'justifyCenter',
              'paddingHorizontal:2',
            ]}
            style={styles.submitBtn}
          >
            <Text
              text={t('Login.forgot')}
              variants={['medium', 'alignSelfCenter', 'row', 'color:primary3']}
            />
          </Button>
        </View>
      </View>
    </View>
  )
}

const MAX_WIDTH = 320

const styles = variantProvider.createComponentStyle(
  () => ({
    submitBtn: {
      maxWidth: MAX_WIDTH,
    },
  }),
  true
)
