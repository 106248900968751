import { Button, Checkbox, Modal, Text, TextInput, View } from '@/components'
import { graphql } from 'gatsby'
import { useForm } from '@codeleap/common'
import {
  DateUtils,
  DisputeUtils,
  Navigation,
  useOTP,
  useUserInformation,
} from '@/utils'
import { AppForms, assignTextStyle, Settings, variantProvider } from '@/app'
import { APIClient } from '@/services'
import { AppStatus } from '@/redux'

export function SignDocumentModal({
  visible,
  toggle,
  settlementId,
  disputeId,
}) {
  const form = useForm(AppForms.SignDocument, {})
  const { ipAddress } = useUserInformation()
  const isValid =
    form.values.full_name &&
    form.values.conditions &&
    form.values.acceptDocument

  const { profile } = APIClient.Session.useSession()
  const { data: dispute } = APIClient.Disputes.disputesManager.useRetrieve({
    id: +disputeId,
  })
  const { isFirstPartie } = DisputeUtils.getParticipantInfo(dispute, profile)

  const { sendCode } = useOTP({
    email: profile?.email,
    onSuccess: async () => {
      const address =
        dispute[`${isFirstPartie ? 'first' : 'second'}_party_info`]?.address ??
        'address not found'
      const data = {
        signature: form.values.full_name,
        ip_address: ipAddress,
        address,
      }

      AppStatus.set('loading')
      try {
        toggle()
        await APIClient.Settlements.signDocument(+settlementId, data)
        AppStatus.set('done')
        Navigation.goToDispute({ id: disputeId, path: 'details' })
      } catch (e) {
        AppStatus.set('idle')
        console.error(e)
        Navigation.goToDashboard()
      }
    },
  })

  return (
    <Modal
      visible={visible}
      toggle={toggle}
      variants={['centered', 'requestSignatureConfirmation']}
      title="Sign document"
      css={styles.modal}
    >
      <View variants={['column', 'marginTop:4']}>
        <TextInput {...form.register('full_name')} />

        <View variants={['column', 'gap:1']}>
          <Text variants={['p1']} text={DateUtils.formatDate(new Date())} />
          <Text
            variants={['h4', 'color:neutral8']}
            text={
              profile?.full_name ??
              `${profile?.first_name} ${profile?.last_name}`
            }
          />
        </View>

        <View
          variants={['alignCenter', 'marginTop:4', 'marginBottom:1']}
          responsiveVariants={{ small: ['alignStart'] }}
        >
          <Checkbox {...form.register('acceptDocument')} label={null} />
          <Text
            css={styles.p}
            text="I agree to everything contained in this document and agree to sign it."
          />
        </View>

        <View
          variants={['alignCenter']}
          responsiveVariants={{ small: ['alignStart'] }}
        >
          <Checkbox {...form.register('conditions')} label={null} />
          <p style={styles.p}>
            I agree to the{' '}
            <a
              style={styles.a}
              href={Settings.ContactINFO.TermsAndConditions}
              target="_blank"
            >
              MediationX Terms and Conditons
            </a>
          </p>
        </View>

        <Button
          text="Sign"
          css={styles.modalButton}
          onPress={sendCode}
          debugName="sign-document"
          disabled={!isValid}
        />
      </View>
    </Modal>
  )
}

const MODAL_WIDTH = 800

const styles = variantProvider.createComponentStyle(
  theme => ({
    modal: {
      width: MODAL_WIDTH,
      ...theme.spacing.padding(4),
    },
    modalButton: {
      width: 320,
      ...theme.spacing.marginTop(4),
      ...theme.spacing.marginHorizontal('auto'),
    },

    p: {
      ...assignTextStyle('p1')(theme).text,
      whiteSpace: 'break-spaces',
      ...theme.spacing.marginLeft(1),
    },
    a: {
      color: theme.colors.primary4,
    },
  }),
  true
)

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
