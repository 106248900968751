export const title = 'MEDIATIONX MEDIATION AGREEMENT'
export const id = 'agreement'
export const content = `
Version 1.6
Last updated 28 February 2025

We are Hexameron Limited, doing business as MediationX ("Company," “we,” “us,” or “our”), a company registered in England at 32 Sextons Meadows, Bury St. Edmunds, England, IP33 2SB. 

We operate the website www.mediationx.com (the “Site”), as well as any other related products and services that refer or link to these legal terms (the “Legal Terms”).The Site is, inter alia, a platform that connects independent mediators with parties looking to resolve disputes, concerns, complaints or grievances, and provides them with a digital environment in which to discuss, share and resolve their issues and differences. MediationX does not itself conduct mediations, but instead facilitates, keeps records of and monitors them, save for mediations conducted by the MediationX AI Mediator (collectively, the “Services”).

You can contact us by e-mail at support@mediationx.com

WHEREAS

Persons may seek or receive or potentially receive mediation services, or otherwise participate in a mediation on or through the Site, whether personally or on behalf of any person or entity.

On the initiation of any such mediation, with initiation occurring on allocation of a case number by us, the aforementioned persons are designated as PARTICIPANTS in that particular mediation.

On such occurrence, these Legal Terms constitute a legally binding agreement made between the aforementioned participants (“the Participants”) and the Company.

Whenever a person who has been invited to join a mediation accepts, they are deemed to be added and to consent to be added as a PARTICIPANT in that particular mediation. On such occurrence, these Legal Terms constitute a legally binding agreement made between all the aforementioned participants (“the Participants”) and the Company.

		For the avoidance of doubt, each Participant is also bound to each other Participant as per the terms and conditions contained herein. 

FURTHERMORE

If and when the Company appoints in writing (or through software means) any person to act as an Independent Registered Mediator in relation to such mediation, and that person accepts such appointment, that person (“the Mediator”)  is also bound by these Legal Terms.
The expressions “the Mediator” or”MediationX Mediator” also include the Company when mediations are carried out by the MediationX AI Mediator.

All Participants and the Mediator agree that by accessing the Services, they have read, understood, and agreed to be bound by all of these Legal Terms.

IF ANY PARTICIPANT OR MEDIATOR DOES NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN THEY ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND MUST DISCONTINUE USE IMMEDIATELY.

For the avoidance of doubt, any human assistant/moderator in relation to the MediationX AI Mediator is, as agent of the Company, bound by these Legal Terms.
 
Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the “Last updated” date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is the responsibility of each Participant and Mediator to periodically review these Legal Terms to stay informed of updates. Each Participant and Mediator will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.  

The services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services. 

We recommend that you print a copy of these Legal Terms for your records.

1.	Appointment of Mediator
1.1.	We confirm that the mediator in each case shall be a person registered with us as a Mediator, and be bound by and on such terms as we have seen fit. Alternatively, the mediation shall be carried out by the MediationX AI Mediator and moderated/assisted by an employee, agent or director of the Company.
1.2.	The Company may appoint more than one Mediator in each case.
1.3.	By accepting an appointment, a Mediator declares that they are independent of each of the Participants and the subject matter of the dispute and further declares that they are qualified and available to serve as mediator in the matter they are appointed in respect of.  

2.	The Mediation
2.1  The Parties agree to attempt in good faith to settle a bonafide dispute while mediating using the MediationX platform.  
2.2  Every Mediator agrees to conduct, and the Participants agree to participate in the Mediation, in accordance with these Legal Terms and pursuant to the MEDIATIONX Mediation Rules. 
3. Mediation Framework. 
3.1  The Mediation will take place remotely via the MEDIATIONX virtual platform
3.2  The Participants agree and consent that for the purposes of the Mediation the Mediator may also utilise third party technology such as telephony, Zoom or WhatsApp, and that such technologies may process any and all data inputted by them. 
3.3 The Participants also agree and consent that the Mediator may be assisted by AI technologies provided by or through or with the assistance of third parties, as facilitated by the Company, and that such technologies may process or receive any and all data inputted by them, including private and confidential information. 
4. Authority and status 
4.1 Each Participant warrants having authority to bind themselves and all other persons taking part on that Participant’s behalf at the Mediation, or any part thereof, to observe the terms of these Legal Terms, including Schedule A, and also having full authority to bind that Participant to the terms of any settlement. 
4.2 Neither a Mediator nor the Company shall be liable to the Parties for any act or omission arising from or in relation to the Mediation, unless the act or omission is proved to have been fraudulent.
4.3 The Participants understand that neither the Mediator nor the Company gives legal or tax advice and agree that they will not make any claim whatsoever whether in tort or in contract or in any other area of law against the Mediator or the Company arising from, or in relation to, or in connection with, the Mediation. 
5. Confidentiality and without prejudice status 
5.1 Each Participant (which term includes without limitation workers, employees, agents, consultants, experts and legal representatives of a Participant ) involved in the Mediation: 
5.1.1  will keep confidential all information arising out of or in connection with the Mediation, including the terms of any settlement, unless otherwise agreed by the Participants in writing, but not including the fact that the Mediation is to take place or has taken place or where disclosure is required by law, or to implement or to enforce terms of settlement or to notify their insurers, insurance brokers and/or accountants and attest to that fact in the form of Schedule “A” hereto (such attestation is deemed to have been made by acceptance of these Legal Terms and use of www.mediationx.com); 
5.1.2  acknowledges that all such information passing between the Participants and the Mediator however communicated, is agreed to be without prejudice to any Participant’s legal position and may not be produced as evidence or disclosed to any judge, arbitrator or other decision-maker in any legal or other formal process, except where otherwise disclosable in law; 
5.1.3  will not make any attempt to or make any audio or video recording or screen capture of any part of the Mediation; 
5.1.4  will ensure that only persons notified in writing to the Independent Registered Mediator or the MediationX AI Mediator, prior to the commencement of any part of the Mediation, may be present in the room where a Participant’s computer is located during any audio and/or video Mediation session, and at all other times when the MediationX platform is being used, Participants will ensure that nobody can see their screen and will take all necessary steps in that regard; 
5.1.5  where they privately disclose to the Mediator any information in confidence before, during or after the Mediation, the Mediator will not disclose that information to any other Participant or person without the consent of the Participant disclosing it. The Mediator may also make disclosure to a Court of relevant jurisdiction where compelled to do so by judicial act of that Court, but will give the affected Participant written notice of any such action by the Court; 
5.1.6  will not make an application to call the Mediator or the Company as a witness, nor require them to produce in evidence any records or notes, or other media of any kind relating to the Mediation, in any litigation, arbitration or other formal process arising out of or in connection with their dispute and the Mediation; nor will the Mediator nor the Company agree to act as a witness, expert, arbitrator or consultant in any such process; and 
5.1.7 where it does make such an application (as listed above), that Participant will fully indemnify the Mediator and the Company in respect of any costs any of them incur in resisting and/or responding to such an application, including reimbursement at the Mediator’s standard hourly rate for the Mediator’s time spent in resisting and/or responding to such an application. 
5.1.8 Each Participant, prospectively and/or retrospectively confirms, that they have no objection to the use of any confidential information in relation to the mediation. 
5.1.9 Each Participant confirms that where the use, revelation or disclosure to us or any mediator or Participant of any information or document, or information contained in such document, would be impermissible or illegal or otherwise a breach of any law, rule, regulation or obligation, they will not use, reveal or disclose any such information or document, or information contained in such document, unless such use/revelation/disclosure is permissible and not a breach of an law, rule, regulation or obligation after obtaining necessary consents and they have expressly obtained such consent in advance of any use/revelation/disclosure. 	
6. Settlement formalities. 
6.1  All offers made in the course of or for the purpose of the Mediation are without prejudice and confidential and shall not be admissible in any litigation or arbitration. 
6.2  No terms of settlement reached at the Mediation will be legally binding until set out in writing in a Settlement Agreement and signed (electronically or otherwise) by or on behalf of each of the Participants. 
6.3 In disputes valued at less than £1500, the Mediator may on request of the parties provide a suggested draft memorandum of understanding for signing. The Company takes no responsibility or liability for such documents or its contents. Furthermore, the Mediator takes no responsibility or liability for such documents or its contents. 
6.4 Participants must only sign (electronically or otherwise) a memorandum of understanding or settlement agreement if they fully understand it, and may wish to seek independent legal advice from a third party such as an independent legal advisor prior to signing. 
6.5 HEALTH WARNING - If they do sign any such agreement, Participants agree that they have either sought independent legal advice or have actively chosen not to based on their own choice and not in reliance of any act/statement/representation of anyone including the Mediator or the Company. 
7. Fees and costs of the Mediation. 
7.1  The fees of the mediation shall be as paid for by the Participants in advance of commencement of the mediation, except if paragraph 7.2 below applies.
7.2 If there exists a written agreement between the Company and a Participant that all mediation fees in respect of disputes involving that Participant shall be paid solely by that Participant, any other Participant involved in that dispute will not be required to pay any fees. 
8. Legal Status and effect of the Mediation 
8.1. This Agreement is governed by the laws of England and Wales and exclusive jurisdiction to decide any matters arising out of or in connection with this Agreement and the Mediation shall lie with a sole arbitrator. Prior to arbitration being commenced parties must attempt to resolve their differences by negotiation, followed by mediation.  

SCHEDULE A DECLARATION OF ATTENDEES/PARTICIPANTS 
I agree with the Company and each of the attendees/Participants at the mediation, including the Mediator, that in consideration of my being present/participating at this mediation, I will not disclose to any person not expressly authorised by all parties to these Legal Terms to receive such information, anything which I have heard or read or seen in the course of this mediation unless obliged by law to do so.

MEDIATIONX MEDIATION RULES

1.	Any mediation conducted via MediationX shall be conducted as per these rules.
2.	These rules may not be modified by any Participant. However Participants may request modification on a case by case basis by e-mailing support@mediationx.com. No approval of any such request as mentioned will be valid unless it is in writing. 
3.	‘Mediation’ includes the entire process from partial initiation to conclusion of the mediation. 
4.	Mediation is partially initiated when the dispute comes to the notice of the Company and a case number is allocated. Mediation is fully initiated when all disputees have agreed to participate.
5.	A Mediation is conducted either by a suitable legal professional being appointed by the Company (“a Mediator”) or by the Company itself. 
6.	Mediation is concluded when a Mediator, or the Company, certifies it having concluded for reasons including settlement reached, abandoned, etc, or alternatively if all Participants withdraws from the mediation in writing, or alternatively if the Mediator or the Company wish to terminate the mediation for any reason. If the Mediation is terminated by the Mediator or the Company without provision of any reason,, a refund may be sought by contacting support@mediationx.com
7.	Mediators may only be appointed by the Company and can only mediate a dispute on accepting their appointment. 
8.	Such appointments are at the discretion of the Company.
9.	Requests for changing mediator may however be made at any time with consent of all Participants by e-mailing support@mediationx.com. On such request being made, the Company will make a final decision in writing on whether to accept or reject the request. If the request is accepted, the Company may allocate a Mediator.
10.	In exceptional cases, requests for the appointment of a particular person as mediator may be made with the consent of all Participants by e-mailing support@mediationx.com On such request being made, the Company will make a final decision in writing on whether to accept or reject the request. If the request is accepted, the Company will invite the said person to mediate the dispute and/or register as an Independent Registered Mediator.
11.	All Participants, Mediators and the Company shall keep all mediation related information or content confidential including the existence of the mediation and any settlement agreement, save for as necessary as per law or for enforcement.
12.	The Mediator or, if the mediation is being carried out by the Company, the Company has sole conduct of a mediation, subject to these rules and the various subsisting agreements and policies. All mediations must be conducted via the company’s website, save with express written permission acquired by the Mediator from the Company by e-mailing support@mediationx.com. 
`
