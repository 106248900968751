import { FormUtils } from '@/utils'
import {
  FileButton,
  TermsCheckboxes,
  CurrencyWidget,
  YesNoWidget,
  View,
  TextInput,
} from '@/components'
import { useState } from 'react'
import { WebInputFile, onUpdate } from '@codeleap/common'

const TextMultiline = ({ ...rest }) => (
  <TextInput
    {...rest}
    debugName='TextMultiline'
    multiline
    style={{ minHeight: 120 }}
  />
)

export const useQuizFormPages = ({
  form,
  title = 'Confidential Questionnaire',
}) => {
  const [file, setFile] = useState<File>()

  const handleFileSelect = (files: WebInputFile[]) => {
    setFile(files[0].file)
  }

  const {
    is_urgent,
    is_pending_lawsuit,
    agree_terms_conditions,
    agree_mediationx_terms,
  } = form.values

  onUpdate(() => {
    if (!is_pending_lawsuit) {
      form.setFormValues({
        pending_lawsuit_court: '',
        pending_lawsuit_case_number: '',
      })
    }
  }, [is_pending_lawsuit])

  const pages = {
    perspective: {
      name: 'perspective',
      title,
      description:
        'This questionnaire collects confidential information exclusively for mediation purposes. Access will be restricted to the mediator, ensuring the privacy and confidentiality of your responses.',
      children: <TextMultiline {...form.register('perspective_description')} />,
      disabled: !FormUtils.isFieldValid(form, 'perspective_description'),
    },
    isUrgent: {
      name: 'isUrgent',
      title,
      children: (
        <View variants={['column', 'gap:4']}>
          <TextMultiline {...form.register('ideal_solution')} />
          <YesNoWidget {...form.register('is_urgent')} />

          {is_urgent && (
            <TextMultiline {...form.register('urgency_description')} />
          )}
        </View>
      ),
    },
    onlyIsUrgent: {
      name: 'onlyIsUrgent',
      title,
      children: (
        <View variants={['column', 'gap:4']}>
          <YesNoWidget {...form.register('is_urgent')} />

          {is_urgent && (
            <TextMultiline {...form.register('urgency_description')} />
          )}
        </View>
      ),
    },
    recentPosition: {
      name: 'recentPosition',
      title,
      children: (
        <View variants={['column']}>
          <TextMultiline {...form.register('recent_position')} />
          <TextMultiline {...form.register('requirements_description')} />
        </View>
      ),
    },
    context: {
      name: 'context',
      title,
      children: <TextMultiline {...form.register('context_description')} />,
    },
    settlements: {
      name: 'settlements',
      title,
      children: (
        <View variants={['column']}>
          <TextMultiline {...form.register('settlements_description')} />
          <TextMultiline {...form.register('roadblocks_description')} />
        </View>
      ),
    },
    autorisedToSettle: {
      name: 'autorisedToSettle',
      title,
      children: (
        <View variants={['column', 'gap:4']}>
          <YesNoWidget {...form.register('authorised_to_settle')} />
          {/* <TextMultiline {...form.register('take_lead_description')} /> */}
        </View>
      ),
    },
    isPendingLawsuit: {
      name: 'isPendingLawsuit',
      title,
      children: (
        <View variants={['column']}>
          <YesNoWidget {...form.register('is_pending_lawsuit')} />

          <View variants={[!is_pending_lawsuit && 'disabled', 'column', 'marginTop:4']}>
            <TextInput {...form.register('pending_lawsuit_court')} />
            <TextInput {...form.register('pending_lawsuit_case_number')} />
          </View>
        </View>
      ),
    },
    strengthsWeaknesses: {
      name: 'strengthsWeaknesses',
      title,
      children: (
        <View variants={['column']}>
          <TextMultiline
            {...form.register('strengths_weaknesses_description')}
          />
          <CurrencyWidget {...form.register('financial_value')} />
          <CurrencyWidget {...form.register('spent_so_far')} />
        </View>
      ),
    },
    suggestions: {
      name: 'suggestions',
      title,
      children: (
        <View variants={['column']}>
          <TextMultiline {...form.register('suggestions')} />
          <TextMultiline {...form.register('additional_info')} />
        </View>
      ),
    },
    relevantDocuments: {
      name: 'relevantDocuments',
      title,
      disabled: !agree_terms_conditions || !agree_mediationx_terms,
      children: (
        <View variants={['column']}>
          <FileButton
            label='Please upload relevant documents'
            files={file ? [file] : undefined}
            onFileRemove={() => setFile(undefined)}
            onFileSelect={handleFileSelect}
          />

          <TermsCheckboxes
            variants={['marginTop:4']}
            termsCheckboxProps={form.register('agree_terms_conditions')}
            mediationxCheckboxProps={form.register('agree_mediationx_terms')}
          />
        </View>
      ),
    },
  }

  return { pages, file, setFile }
}
