import { NavigateOptions } from '@reach/router'
import {
  ROUTES,
  AUTHENTICATED_ROUTES,
  NON_AUTHENTICATED_ROUTES,
  IS_SSR,
} from '@/app'
import { navigate as navigateGatsby } from 'gatsby'
import { TypeGuards } from '@codeleap/common'
import { Dispute, DisputeThread } from '@/types'

// @ts-ignore
type CreateRoutes<T, Prefix extends string = ''> = {
  [K in keyof T & string]: T[K] extends string
    ? `${Prefix}${Prefix extends '' ? '' : '.'}${K}`
    : CreateRoutes<T[K], `${Prefix}${Prefix extends '' ? '' : '.'}${K}`>
}[keyof T]

export type RoutePath = CreateRoutes<typeof ROUTES>

function getRoutePath(path: RoutePath): string {
  let route = ROUTES

  if (path?.includes('.')) {
    const indexesAccess = path?.split('.')

    for (const index of indexesAccess) {
      route = route?.[index]
    }
  } else {
    route = route?.[path]
  }

  return (route ?? path) as string
}

function getSearchParams() {
  if (IS_SSR) return {}
  const searchParams = new URLSearchParams(window.location.search)
  return Object.fromEntries(searchParams)
}

type NavigateOption<T> = {
  route?: any
  params?: Record<string, string>
  routeParams?: Record<string, string>
} & NavigateOptions<T>

const defaultNavigateOptions = {
  route: null,
  params: null,
}

function navigate<S = any>(
  route: RoutePath,
  options: NavigateOption<S> = defaultNavigateOptions
) {
  const {
    route: routeParam = null,
    params = null,
    routeParams = null,
    ...navigateParams
  } = options

  let path = getRoutePath(route)

  if (!TypeGuards.isNil(routeParam)) {
    path = `${path}/${String(routeParam)}`
  }

  if (!TypeGuards.isNil(routeParams)) {
    Object.keys(routeParams)?.forEach(paramKey => {
      const value = routeParams?.[paramKey]

      if (value == null) {
        path = path.replace(`/{{${paramKey}}}`, '')
      } else {
        path = path.replace(`{{${paramKey}}}`, value)
      }
    })
  }

  if (!TypeGuards.isNil(params)) {
    let searchParams = ''

    Object.keys(params)?.forEach((paramKey, index) => {
      const newParam = `${paramKey}=${encodeURIComponent(params?.[paramKey])}`
      const separator = index === 0 ? '' : '&'

      searchParams = `${searchParams}${separator}${newParam}`
    })

    path = `${path}?${searchParams}`
  }

  navigateGatsby(path, navigateParams)
}

function inHome(pathname: string) {
  return pathname.includes('/home/')
}

function includesOnRoutes(
  pathname: string,
  routes: Record<string, Record<string, string>>
) {
  for (const category in routes) {
    for (const route in routes[category]) {
      if (routes[category][route] === pathname?.slice(0, -1)) {
        return true
      }
    }
  }
  return false
}

function isPathNotAuthenticated(pathname: string) {
  return includesOnRoutes(pathname, NON_AUTHENTICATED_ROUTES)
}

function isPathAuthenticated(pathname: string) {
  return includesOnRoutes(pathname, AUTHENTICATED_ROUTES)
}

function navigateToSignDocument({ disputeId, settlementId }) {
  navigate('SignDocument', {
    params: { dispute: disputeId, settlement: settlementId },
  })
}

const goToDashboard = () => navigate('Disputes.List')

type DisputeNavigateOptions = {
  id: Dispute['id']
  path?: 'details' | 'settlements' | 'participants' | 'chat'
  params?: Record<string, string>
}

const goToDispute = ({
  id,
  path = 'details',
  params,
}: DisputeNavigateOptions) => {
  if (path) {
    const _path = path.charAt(0).toUpperCase() + path.slice(1)
    return navigate(`Disputes.Dispute${_path}`, {
      routeParams: { id: String(id) },
      params,
    })
  }
  return navigate('Disputes.Dispute', { route: String(id), params })
}

export const Navigation = {
  navigate,
  getRoutePath,
  getSearchParams,
  isPathAuthenticated,
  isPathNotAuthenticated,

  inHome,

  navigateToSignDocument,
  goToDashboard,
  goToDispute,
}
