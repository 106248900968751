import React from 'react'
import {
  AppStatusOverlay,
  DebugModal,
  PaywallModal,
  OnboardingModal,
  ParticipantInformationModal,
  HomePage,
} from '@/components'
import { AppStatus } from '@/redux'
import { onUpdate } from '@codeleap/common'
import { APIClient } from '@/services'
import { graphql, navigate, PageProps } from 'gatsby'

export const Overlays = () => (
  <>
    <PaywallModal />
    <DebugModal />
    <AppStatusOverlay />
  </>
)

export const AuthenticatedOverlays = ({ isLoggedIn }) =>
  isLoggedIn ? (
    <>
      <OnboardingModal />
      <ParticipantInformationModal />
    </>
  ) : (
    <></>
  )

export default (props: PageProps) => {
  const session = APIClient.Session.useSession()
  const { isLoggedIn } = session

  onUpdate(() => {
    AppStatus.setReady(isLoggedIn)

    if (isLoggedIn) {
      navigate('/disputes')
    }
  }, [isLoggedIn])

  return <HomePage />
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
