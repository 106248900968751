import { variantProvider } from '@/app'
import {
  Page,
  View,
  BannerSection,
  CategorySection,
  StrenghtsSection,
  HowItWorksSection,
  PricingSection,
} from '@/components'

export const HomePage = () => {
  return (
    <Page pageTitle="Home" centerContent={false}>
      <View
        style={styles.wrapper}
        variants={['fullWidth', 'column', 'backgroundColor:neutral1']}
      >
        <BannerSection />

        <CategorySection />

        <StrenghtsSection />

        <HowItWorksSection />

        <PricingSection />
      </View>
    </Page>
  )
}

const styles = variantProvider.createComponentStyle(
  theme => ({
    wrapper: {
      ...theme.presets.fullWidth,
      minHeight: '90vh',
    },
  }),
  true
)
