import { AppStatus, useAppSelector } from '@/redux'
import { getFirebase } from '../../firebaseApp'
import {
  onUpdate,
  ReactQuery,
  useIsomorphicEffect,
  useRef,
} from '@codeleap/common'
import { create, retrieve, update } from './views'
import { QueryKeys } from './queryKeys'
import { Navigation, useToggleDevMode } from '@/utils'
import { useState } from 'react'
import { queryClient } from '../queryClient'
import { OSAlert } from '@/components'
import { LocalStorage } from '@/app'

const warnEmailInUse = () => {
  OSAlert.error({
    body: 'This email address is already taken',
    title: 'Email address in use',
  })
}

const SPLASH_TRANSITION = 1000

export function useSession(root = false) {
  const devMode = useToggleDevMode(root)

  const authFinished = useAppSelector(store => store.AppStatus.authFinished)
  const initialAuthResolved = useAppSelector(
    store => store.AppStatus.hasResolvedInitialAuth
  )

  const [hasFirebaseUser, setHasFirebaseUser] = useState(null)

  const profile = ReactQuery.useQuery({
    queryKey: QueryKeys.me.key,
    queryFn: retrieve,
    refetchOnMount(q) {
      return q.state.dataUpdateCount == 0
    },
    refetchOnWindowFocus: false,
    retry: false,
    enabled: root && devMode.loaded,
    onError() {
      QueryKeys.me.setData(null)
    },
  })

  const createProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: create,
    onSuccess: () => {
      QueryKeys.me.refresh()
    },
  })

  const updateProfile = ReactQuery.useMutation({
    mutationKey: QueryKeys.create.key,
    mutationFn: update,
    onSuccess: () => {
      QueryKeys.me.refresh()
    },
  })

  const logout = (withDone = true) => {
    return new Promise<void>(async resolve => {
      if (withDone) {
        AppStatus.set('loading')
      }

      const password = LocalStorage.getItem('PASSWORD')
      LocalStorage.clear()
      if (!!password) {
        LocalStorage.setItem('PASSWORD', password)
      }

      const firebase = await getFirebase()

      if (withDone) {
        AppStatus.set('loading')
      }
      setTimeout(() => {
        firebase.auth().signOut()
        queryClient.client.clear()
        AppStatus.clearModals()

        setTimeout(() => {
          if (withDone) {
            AppStatus.set('done')
          }

          setTimeout(() => {
            Navigation.navigate('Home')
            resolve()
          })
        }, 1000)
      }, 2000)
    })
  }

  const loginResolved = typeof profile.data !== 'undefined'

  const onOnboardingFinished = () => {
    AppStatus.authFinished()
  }

  const onInitialAuthResolved = () => {
    AppStatus.set('splash')

    setTimeout(() => {
      AppStatus.initialAuthResolved()
    }, 35000)
  }

  const toggleRememberInformation = async (rememberMe: boolean) => {
    const firebase = await getFirebase()
    const persistance = rememberMe
      ? firebase.auth.Auth.Persistence.SESSION
      : firebase.auth.Auth.Persistence.NONE
    firebase.auth().setPersistence(persistance)
  }

  const requestPasswordReset = async (email: string) => {
    await AppStatus.set('loading')

    try {
      const firebase = await getFirebase()
      const response = await firebase.auth().sendPasswordResetEmail(email)

      return response
    } catch (e) {
      await AppStatus.set('idle')
      console.error('Password reset', e, 'Auth')
      OSAlert.error({
        title: 'Failed to send email',
        body: 'There was an error during password reset',
      })
      throw e
    }
  }

  const reauthenticate = async (password: string) => {
    const firebase = await getFirebase()

    return firebase
      .auth()
      .signInWithEmailAndPassword(profile.data.email, password)
  }

  const resolvedAuth = useRef(false)
  const isMediator = profile.data?.role === 'mediator'

  useIsomorphicEffect(() => {
    if (!root) return

    let unsubscribe: () => void

    const init = async () => {
      const firebase = await getFirebase()

      unsubscribe = firebase.auth().onAuthStateChanged(async user => {
        if (user?.uid) {
          LocalStorage.setItem('PERSIST_AUTH', 'true')
        } else {
          LocalStorage.removeItem('PERSIST_AUTH')
        }

        if (user) {
          setHasFirebaseUser(user?.uid)

          setTimeout(() => {
            QueryKeys.me.refresh()
          }, 1500)
        } else {
          await QueryKeys.me.setData(null)
        }

        if (!resolvedAuth.current) {
          setTimeout(() => {
            AppStatus.set('idle')
          }, SPLASH_TRANSITION)
        }

        resolvedAuth.current = true
      })
    }

    init()
  }, [])

  onUpdate(() => {
    if (!profile.isFetched && root && devMode.loaded) {
      profile
        .refetch()
        .then(result => {
          if (result.isSuccess) {
            AppStatus.authFinished()
          }
        })
        .catch(() => {})
    }
  }, [devMode.loaded])

  onUpdate(() => {
    if (loginResolved && root && !initialAuthResolved) {
      onInitialAuthResolved()
    }
  }, [loginResolved])

  return {
    profile: profile.data,
    reauthenticate,
    updateProfile,
    createProfile,
    logout,
    isLoggedIn: !!profile.data,
    loginResolved,
    authFinished,
    onOnboardingFinished,
    requestPasswordReset,
    warnEmailInUse,
    hasFirebaseUser,
    profileQuery: profile,
    toggleRememberInformation,
    isMediator,
  }
}
