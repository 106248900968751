export const title = 'TERMS AND CONDITIONS'
export const id = 'terms'
export const content = `
Version 1.8
Last updated 27 February 2025
 

AGREEMENT TO OUR LEGAL TERMS
 
We are Hexameron Limited, doing business as MediationX ("Company," “we,” “us,” or “our”), a company registered in England at 32 Sextons Meadows, Bury St. Edmunds, England, IP33 2SB. 

We operate the website www.mediationx.com (the “Site”), as well as any other related products and services that refer or link to these legal terms (the “Legal Terms”). The Site is a platform for dispute resolution and, inter alia, connects mediators with parties looking to resolve disputes, concerns, complaints or grievances, and provides them with a digital environment in which to discuss, share and resolve their issues and differences. MediationX does not itself conduct mediations, but instead facilitates, keeps records of and monitors them, and also communicates with users via, inter alia, e-mail, save that MediationX may carry out mediations when it uses the MediationX AI Mediator which is assisted by a moderator, employee or agent of the Company (collectively, the “Services”).

You can contact us by e-mail at support@mediationx.com

These Legal Terms constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you”), and Hexameron Limited, and concerning your access to and use of the Services. 

You agree that by accessing the Services, you have read, understood, and agreed to be bound by all of these Legal Terms. IF YOU DO NOT AGREE WITH ALL OF THESE LEGAL TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
 
Supplemental terms and conditions or documents that may be posted on the Services from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Legal Terms from time to time. We will alert you about any changes by updating the “Last updated” date of these Legal Terms, and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Legal Terms to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Legal Terms by your continued use of the Services after the date such revised Legal Terms are posted.  

The services are intended for users who are at least 18 years old. Persons under the age of 18 are not permitted to use or register for the Services. 

We recommend that you print a copy of these Legal Terms for your records.


1. OUR SERVICES

The information provided when using the Services is not intended for distribution to or use by any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation or which would subject us to any registration requirement within such jurisdiction or country. Accordingly, those persons who choose to access the Services from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable. 


2. INTELLECTUAL PROPERTY RIGHTS

Our intellectual property

We are the owner or the licensee of all intellectual property rights in our Services, including all source code, databases, functionality, software, website designs, audio, video, text, photographs, and graphics in the Services (collectively, the “Content”), as well as the trademarks, service marks, and logos contained therein (the “Marks”).

Our Content and Marks are protected by copyright and trademark laws (and various other intellectual property rights and unfair competition laws) and treaties in the United Kingdom and around the world.

The Content and Marks are provided in or through the Services “AS IS” for you to help resolve disputes only. You must be a party to or connected with such disputes. 

Your use of our Services

Subject to your compliance with these Legal Terms, including the "PROHIBITED ACTIVITIES" section below, we grant you a non-exclusive, non-transferable, revocable license to access:

■	access the Services; 
■	download or print a copy of any portion of the Content to which you have properly gained access (save that you must not do so, or do any other act, which would or could breach privilege or confidentiality of any mediation or breach any other law/rule/regulation/obligation relating to confidentiality or otherwise),
 
solely for your personal or commercial/business purpose in relation to a dispute you are a party to or otherwise connected with. You are not granted any license whatsoever in relation to any commercial or external business purpose or any purpose which may compete with the Company. 

Except as set out in this section or elsewhere in our Legal Terms, no part of the Services and no Content or Marks may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated, transmitted, distributed, sold, licensed, or otherwise exploited for any purpose whatsoever, without our express prior written permission.
 
If you wish to make any use of the Services, Content, or Marks other than as set out in this section or elsewhere in our Legal Terms, please address your request to: legal@mediationx.com. If we ever grant you the permission to post, reproduce, or publicly display any part of our Services or Content, you must identify us as the owners or licensors of the Services, Content, or Marks and ensure that any copyright or proprietary notice appears or is visible on posting, reproducing, or displaying our Content.

We reserve all rights not expressly granted to you in and to the Services, Content, and Marks.

Any breach of these Intellectual Property Rights will constitute a material breach of our Legal Terms and your right to use our Services will terminate immediately.

Your submissions and contributions

Please review this section and the “PROHIBITED ACTIVITIES” section carefully prior to using our Services to understand the (a) rights you give us and (b) obligations you have when you post or upload any content through the Services.

Submissions: By directly sending us any question, comment, suggestion, idea, feedback, or other information about the Services ("Submissions"), you agree to assign to us all intellectual property rights in such Submission. You agree that we shall own this Submission and be entitled to its unrestricted use and dissemination for any lawful purpose, commercial or otherwise, without acknowledgment or compensation to you.

Contributions: The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality during which you may create, submit, post, send, upload, display, transmit, publish, distribute, or broadcast content, information and materials to us or through the Services, including but not limited to text, documents, PDFs, writings, video, audio, photographs, music, graphics, comments, reviews, rating suggestions, personal information, or other material (“Contributions”). Any Submission that is publicly posted shall also be treated as a Contribution.
 
You understand that Contributions may be viewable by other users of the Services and the Company itself.
 
When you post Contributions, you grant us a license (including use of your name, trademarks, and logos): By posting any Contributions, you grant us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to: use, copy, reproduce, distribute, sell, resell, publish, broadcast, retitle, store, publicly perform, publicly display, reformat, translate, excerpt (in whole or in part), and exploit your Contributions (including, without limitation, your image, name, and voice) for any purpose, commercial, advertising, or otherwise, to prepare derivative works of, or incorporate into other works, your Contributions, and to sublicense the licenses granted in this section. Our use and distribution may occur in any media formats and through any media channels.
 
This license includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide.

Save that any Contribution within a mediation will be treated as confidential, subject to the terms of the MediationX Mediation Agreement.  
 
You are responsible for what you post or upload: By sending us Submissions and/or posting Contributions through any part of the Services or making Contributions accessible through the Services, you:

■	confirm that you have read and agree with our “PROHIBITED ACTIVITIES” and will not post, send, publish, upload, or transmit through the Services any Submission nor post any Contribution that is illegal, harassing, hateful, harmful, defamatory, obscene, bullying, abusive, discriminatory, threatening to any person or group, sexually explicit, false, inaccurate, deceitful, or misleading;
■	to the extent permissible by applicable law, waive any and all moral rights to any such Submission and/or Contribution;
■	warrant that any such Submissions and/or Contributions are original to you or that you have the necessary rights and licenses to submit such Submissions and/or Contributions and that you have full authority to grant us the above-mentioned rights in relation to your Submissions and/or Contributions; 
■	warrant and represent that your Submissions and/or Contributions do not constitute confidential information that you are not able to share.
■	warrant and represent that you are permitted to make each and every part of your Submissions and/or Contributions and that you will not use, reveal or disclose any information or document, or information contained in such document, where such use, revelation or disclosure would be impermissible or illegal or otherwise a breach of any law, rule, regulation or obligation
■	warrant and represent that your Submissions and/or Contributions are neither illegal nor breach any law, rule, regulation or obligation. 
 
You are solely responsible for your Submissions and/or Contributions and you expressly agree to reimburse us for any and all losses that we may suffer because of your breach of (a) this section, (b) any third party’s intellectual property rights, or (c) applicable law.

We may remove or edit your Content: Although we have no obligation to monitor any Contributions, we shall have the right to remove or edit any Contributions at any time without notice if in our reasonable opinion we consider such Contributions harmful or in breach of these Legal Terms. If we remove or edit any such Contributions, we may also suspend or disable your account and report you to the authorities.

Copyright infringement

We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately refer to the “COPYRIGHT” section below.
 
3. USER REPRESENTATIONS
 
By using the Services, you represent and warrant that: (1) all registration information you submit will be true, accurate, current, and complete; (2) you will maintain the accuracy of such information and promptly update such registration information as necessary; (3) you have the legal capacity and you agree to comply with these Legal Terms; (4) you are not under the age of 18; (5) you are not a minor in the jurisdiction in which you reside, or if a minor, you have received parental permission to use the Services; (6) you will not access the Services through automated or non-human means, whether through a bot, script, or otherwise; (7) you will not use the Services for any illegal or unauthorized purpose; (8) you have not previously been barred by the Company; (9) if you are asked by the Company to provide proof of any registration information you agree to do so within 7 working days; and (10) your use of the Services will not violate any applicable law or regulation.
 
If you provide any information that is untrue, inaccurate, not current, or incomplete, or fail to provide any information or proof we require, we have the right to suspend or terminate your account and refuse any and all current or future use of the Services (or any portion thereof). 
     
4. USER REGISTRATION
     
You may be required to register to use some or all of the Services. You agree to keep your password confidential and will be responsible for any and all use of your account and password by you or any third-party. You must set a password that is very strong and not easily guessable. 

You agree to keep any one time passwords that you receive confidential. 

We reserve the right to remove, reclaim, or change an an account if we determine, in our sole discretion, that such use is inappropriate, obscene, or otherwise objectionable.

You must only create and use one account, and your account is neither transferable nor assignable. 

     
5. MEDIATIONS 

1.	The Services enable you, on payment being satisfied, to be allocated, at the Company’s discretion, either the MediationX AI Mediator,  or an Independent Registered Mediator. 

2.	All Independent Registered Mediators are either
2.1.	persons who are not in any way employees or workers or agents (of any kind) or partners of the Company. No acts or omissions or literature of the Company are to be deemed to indicate such a relationship.
2.2.	Or directors of the Company who, when acting as an Independent Registered Mediator, are doing so in their capacity as a legal professional.

3.	The MediationX AI Mediator provides artificial intelligence based mediation while being moderated/assisted by human moderators who are employees, agents or directors of the Company.

4.	On inputting data about a dispute on the Company’s website, you are making an offer to receive mediation services and use our Services as per the Legal Terms.

5.	On receipt of your offer, the Company may accept it (for the avoidance of doubt, there is no obligation on the Company to accept it and the Company will not accept it if doing so would be contrary to law, for example due to the subject matter of the dispute) by providing you a quotation/invoice, on terms that our acceptance is subject to you making the necessary payment as per the price set out in the quotation/invoice. If the requisite payment is made, we will either 
5.1.	make one or more Independent Registered Mediators aware of this fact. They may choose to accept, bearing in mind, inter alia, their availability, skills, expertise and knowledge. OR
5.2.	allocate you the MediationX AI Mediator

6.	If no Independent Registered Mediators accept within 3 working days, or we have not allocated you the MediationX AI Mediator within that timeframe, we will issue you a full refund. 

7.	If an Independent Registered Mediator does accept but for any reason has to be replaced (with such replacement happening at the sole discretion of the Company), the Company will make one or more other Independent Registered Mediators aware of this fact. They may choose to accept, bearing in mind, inter alia, their availability, skills, expertise and knowledge. However, if no replacement Independent Registered Mediators accept within 3 working days, we will issue you a full refund. Alternatively, you will be offered the option to continue with the MediationX AI Mediator.

8.	All our mediations take place subject to the MediationX Mediation Agreement, which is deemed incorporated herein. The prices mentioned in our invoices are a fixed fee, subject to a Fair Use of Mediators’ Time policy.

9.	Fair Use of Mediators’ Time Policy
9.1.	Where fees paid are up to a total of £50, 1 hour
9.2.	Where fees paid are up to a total of £100, 2 hours
9.3.	Where fees paid are up to a total of £500, 5 hours
9.4.	Where fees paid are up to a total of £1000, 7 hours
9.5.	Where fees paid are up to a total of £3000, 15 hours
9.6.	Where fees paid are up to a total of £5000, 25 hours 

10.	In cases where the MediationX AI Mediator has been allocated, references to “Mediators’ Time” is to be read as including Moderators’ time.

11.	If the fair use policy is exceeded, the Independent Registered Mediator may request additional fees in order to continue or we may request additional fees for the continuance of the MediationX AI Mediator. 

12.	We do not guarantee that mediations will result in settlements. Furthermore, all mediations are subject to availability, and we cannot guarantee that Independent Registered Mediators or the MediationX AI Mediator will be available to mediate a dispute. We reserve the right to discontinue mediations at any time for any reason.

13.	All prices for mediations and our Services are subject to change.

 
6. PAYMENT 
 
You may have to pay the Company for some or all of the Services or for a service provided to you or to be provided to you by us or our Independent Registered Mediators. If this is the case, you will first be provided with a quotation/invoice for each instance a payment is required. The quotation/invoice will be comprehensive and include fees payable to the Company and/or Independent Registered Mediators.

Payment will be due on your acceptance of the quotation/invoice.

On acceptance, you will owe fees to the Independent Registered Mediator and/or to us. We shall, as agent or otherwise for any Independent Registered Mediator, pay to such Independent Registered Mediator fees as contractually may be due to them. We reserve the right to charge Independent Registered Mediators a commission, charge or referral fee. 

You will be deemed to have accepted the quotation by signifying your assent to us or by making payment to us pursuant to receiving the quotation.

We accept the following forms of payment:

-  Bank Transfer
-  Credit/Debit Cards

You agree to provide current, complete, and accurate purchase and account information for all payments made via or in connection with the Services. You further agree to promptly update account and payment information, including email address, payment method, and payment card expiration date, so that we can complete your transactions and contact you as needed. VAT will be added to the price as deemed required by us. We may change prices at any time. All payments shall be in GBP (Pound Sterling).  
 
You agree to pay all charges at the prices then in effect for your purchases, and you authorise us to charge your chosen payment method for any such amounts. We reserve the right to correct any errors or mistakes in pricing, even if we have already requested or received payment.
 
We reserve the right to refuse any order placed through the Services. We may, in our sole discretion, limit or cancel numbers of mediations purchased per person, per household, or per order. These restrictions may include orders placed by or under the same customer account, the same payment method, and/or orders that use the same billing or shipping address.

We may from time to time have promotional offers. 

Payment will also be satisfied if paid by the other party, at that time or in advance. 

7. REFUNDS POLICY 
     
All sales are final and no refund will be issued, save at the Company’s sole discretion.

Save that, if an allocated Independent Registered Mediator or the MediationX AI Mediator is unsuccessful in inviting all responding parties to join the mediation, a full refund of the mediation fee will be issued to the commencing party after deduction of a £15 administrative fee.
       
8. ACCOUNT DELETION 
 
You can delete your account at any time by logging into your account. 

If you are unsatisfied with our Services, or wish to make any complaints, please e-mail us at support@mediationx.com


9. SOFTWARE

We may include software for use in connection with our Services. If such software is accompanied by an end user license agreement (“EULA”), the terms of the EULA will also govern your use of the software. If such software is not accompanied by a EULA, then we grant to you a non-exclusive, revocable, personal, and non-transferable license to use such software solely in connection with our services and in accordance with these Legal Terms. Any software and any related documentation is provided “AS IS” without warranty of any kind, either express or implied, including, without limitation, the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. You accept any and all risk arising out of use or performance of any software. You may not reproduce or redistribute any software except in accordance with the EULA or these Legal Terms.

10. PROHIBITED ACTIVITIES
 
You may not access or use the Services for any purpose other than that for which we make the Services available. The Services may not be used in connection with any commercial endeavors.
 
As a user of the Services, you agree not to:

■	Systematically retrieve data or other content from the Services to create or compile, directly or indirectly, a collection, compilation, database, or directory without express written permission from us.
■	Make any unauthorized use of the Services, including collecting usernames and/or email addresses of users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretenses.
■	Circumvent, disable, or otherwise interfere with security-related features of the Services, including features that prevent or restrict the use or copying of any Content or enforce limitations on the use of the Services and/or the Content contained therein.
■	Engage in unauthorized framing of or linking to the Services.
■	Trick, defraud, or mislead us and other users, especially in any attempt to learn sensitive account information such as user passwords or other sensitive or confidential information.
■	Make improper use of our support services or submit false reports of abuse or misconduct.
■	Engage in any automated use of the system, such as using scripts to send comments or messages, or using any data mining, robots, or similar data gathering and extraction tools.
■	Interfere with, disrupt, or create an undue burden on the Services or the networks or services connected to the Services.
■	Attempt to impersonate another user or person or use the username of another user.
■	Use any information obtained from the Services in order to harass, abuse, or harm another person.
■	Use the Services as part of any effort to compete with us or otherwise use the Services and/or the Content for any revenue-generating endeavor or commercial enterprise.
■	Decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Services.
■	Attempt to bypass any measures of the Services designed to prevent or restrict access to the Services, or any portion of the Services.
■	Harass, annoy, intimidate, or threaten any of our employees or agents engaged in providing any portion of the Services to you.
■	Delete the copyright or other proprietary rights notice from any Content.
■	Copy or adapt the Services’ software, including but not limited to Flash, PHP, HTML, JavaScript, or other code.
■	Upload or transmit (or attempt to upload or to transmit) viruses, Trojan horses, or other material, including excessive use of capital letters and spamming (continuous posting of repetitive text), that interferes with any party’s uninterrupted use and enjoyment of the Services or modifies, impairs, disrupts, alters, or interferes with the use, features, functions, operation, or maintenance of the Services.
■	Upload or transmit (or attempt to upload or to transmit) any material that acts as a passive or active information collection or transmission mechanism, including without limitation, clear graphics interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or other similar devices (sometimes referred to as “spyware” or “passive collection mechanisms” or “pcms”).
■	Except as may be the result of standard search engine or Internet browser usage, use, launch, develop, or distribute any automated system, including without limitation, any spider, robot, cheat utility, scraper, or offline reader that accesses the Services, or using or launching any unauthorized script or other software.
■	Access the Services from a device that is not up to date in software  
■	Access the Services from a device that does not contain up to date and running anti-virus software and measures
■	Disparage, tarnish, or otherwise harm, in our opinion, us and/or the Services.
■	Use, reveal or disclose any information or document, or information contained in such document, where such use/revelation/disclosure would be impermissible or illegal or otherwise a breach of any law, rule, regulation or obligation.
■	Use the Services in a manner inconsistent with any applicable laws or regulations.


11. USER GENERATED CONTRIBUTIONS
    
The Services may invite you to chat, contribute to, or participate in blogs, message boards, online forums, and other functionality, and may provide you with the opportunity to create, submit, post, display, transmit, perform, publish, distribute, or broadcast content and materials to us or on the Services, including but not limited to text, writings, video, audio, photographs, graphics, comments, suggestions, or personal information or other material (collectively, "Contributions"). Contributions may be viewable by other users of the Services. As such, any Contributions you transmit may be treated as non-confidential and non-proprietary, save that Contributions within a mediation will be treated as confidential subject to the MediationX Mediation Agreement. When you create or make available any Contributions, you thereby represent and warrant that:

■	The creation, distribution, transmission, public display, or performance, and the accessing, downloading, or copying of your Contributions do not and will not infringe the proprietary rights, including but not limited to the copyright, patent, trademark, trade secret, or moral rights of any third party.
■	You are the creator and owner of or have the necessary licenses, rights, consents, releases, and permissions to use and to authorize us, the Services, and other users of the Services to use your Contributions in any manner contemplated by the Services and these Legal Terms.
■	You have the written consent, release, and/or permission of each and every identifiable individual person in your Contributions to use the name or likeness of each and every such identifiable individual person to enable inclusion and use of your Contributions in any manner contemplated by the Services and these Legal Terms.
■	Your Contributions are not false, inaccurate, or misleading.
■	Your Contributions are not unsolicited or unauthorized advertising, promotional materials, pyramid schemes, chain letters, spam, mass mailings, or other forms of solicitation.
■	Your Contributions are not obscene, lewd, lascivious, filthy, violent, harassing, libelous, slanderous, or otherwise objectionable (as determined by us).
■	Your Contributions do not ridicule, mock, disparage, intimidate, or abuse anyone.
■	Your Contributions do not advocate the violent overthrow of any government or incite, encourage, or threaten physical harm against another.
■	Your Contributions do not violate any applicable law, regulation, or rule.
■	Your Contributions do not violate the privacy or publicity rights of any third party.
■	Your Contributions do not violate any applicable law concerning child pornography, or otherwise intended to protect the health or well-being of minors.
■	Your Contributions do not include any offensive comments that are connected to race, national origin, gender, sexual preference, or physical handicap.
■	Your Contributions do not otherwise violate, or link to material that violates, any provision of these Legal Terms, or any applicable law or regulation.
  
Any use of the Services in violation of the foregoing violates these Legal Terms and may result in, among other things, termination or suspension of your rights to use the Services.
            
12. CONTRIBUTION LICENSE
     
Save that everything said and done in a mediation will be confidential in a mediation, subject to the MediationX Mediation Agreement, by posting your Contributions to any part of the Services, you automatically grant, and you represent and warrant that you have the right to grant, to us an unrestricted, unlimited, irrevocable, perpetual, non-exclusive, transferable, royalty-free, fully-paid, worldwide right, and license to host, use, copy, reproduce, disclose, sell, resell, publish, broadcast, retitle, archive, store, cache, publicly perform, publicly display, reformat, translate, transmit, excerpt (in whole or in part), and distribute such Contributions (including, without limitation, your image and voice) for any purpose, commercial, advertising, or otherwise, and to prepare derivative works of, or incorporate into other works, such Contributions, and grant and authorize sublicenses of the foregoing. The use and distribution may occur in any media formats and through any media channels.
     
This license will apply to any form, media, or technology now known or hereafter developed, and includes our use of your name, company name, and franchise name, as applicable, and any of the trademarks, service marks, trade names, logos, and personal and commercial images you provide. You waive all moral rights in your Contributions, and you warrant that moral rights have not otherwise been asserted in your Contributions.
     
We do not assert any ownership over your Contributions. You retain full ownership of all of your Contributions and any intellectual property rights or other proprietary rights associated with your Contributions. We are not liable for any statements or representations in your Contributions provided by you in any area on the Services. You are solely responsible for your Contributions to the Services and you expressly agree to exonerate us from any and all responsibility and to refrain from any legal action against us regarding your Contributions. 
     
We have the right, in our sole and absolute discretion, (1) to edit, redact, or otherwise change any Contributions; (2) to re-categorize any Contributions to place them in more appropriate locations on the Services; and (3) to pre-screen or delete any Contributions at any time and for any reason, without notice. We have no obligation to monitor your Contributions. 

           
13. GUIDELINES FOR REVIEWS
     
We may provide you areas on the Services to leave reviews or ratings. When posting a review, you must comply with the following criteria: (1) you should have firsthand experience with the person/entity being reviewed; (2) your reviews should not contain offensive profanity, or abusive, racist, offensive, or hateful language; (3) your reviews should not contain discriminatory references based on religion, race, gender, national origin, age, marital status, sexual orientation, or disability; (4) your reviews should not contain references to illegal activity; (5) you should not be affiliated with competitors if posting negative reviews; (6) you should not make any conclusions as to the legality of conduct; (7) you may not post any false or misleading statements; and (8) you may not organize a campaign encouraging others to post reviews, whether positive or negative.
    
We may accept, reject, or remove reviews in our sole discretion. We have absolutely no obligation to screen reviews or to delete reviews, even if anyone considers reviews objectionable or inaccurate. Reviews are not endorsed by us, and do not necessarily represent our opinions or the views of any of our affiliates or partners. We do not assume liability for any review or for any claims, liabilities, or losses resulting from any review. By posting a review, you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-free, fully paid, assignable, and sublicensable right and license to reproduce, modify, translate, transmit by any means, display, perform, and/or distribute all content relating to reviews.

         
       
14. THIRD-PARTY WEBSITES AND CONTENT
     
The Services may contain (or you may be sent by us or via the Site,) links to other websites ("Third-Party Websites") as well as articles, photographs, text, graphics, pictures, designs, music, sound, video, information, applications, software, and other content or items belonging to or originating from third parties ("Third-Party Content"). Such Third-Party Websites and Third-Party Content are not investigated, monitored, or checked for accuracy, appropriateness, security or completeness by us, and we are not responsible for any Third- Party Websites accessed through the Services or through any e-mail sent by us or any Third-Party Content posted on, available through, viewed on or installed from the Services, including the content, accuracy, offensiveness, opinions, reliability, privacy practices, security or other policies of or contained in the Third-Party Websites or the Third-Party Content. Inclusion of, linking to, or permitting the use or viewing or installation of any Third-Party Websites or any Third-Party Content does not imply approval or endorsement thereof by us. 

If you decide to leave the Services and/or access the Third-Party Websites or use or view or install any Third-Party Content, you do so at your own risk.

You should review the applicable terms and policies, including privacy and data gathering practices, of any website to which you navigate from any e-mail from us or the Services or relating to any applications you use or install from the Services.

Any purchases or transactions you make through Third-Party Websites may be through other websites and from other companies, and we take no responsibility whatsoever in relation to such purchases which are exclusively between you and the applicable third party. 

You agree and acknowledge that we do not endorse the products or services offered on Third-Party Websites and you shall hold us blameless from any harm caused by your purchase of such products or services.

Additionally, you shall hold us blameless from any losses sustained by you or harm caused to you relating to or resulting in any way from any Third-Party Content or any contact with Third-Party Websites.
             
15. ADVERTISERS
     
We may allow advertisers to display their advertisements and other information in certain areas of the Services, such as sidebar advertisements or banner advertisements. We simply provide the space to place such advertisements, and we have no other relationship with advertisers.
       
16. SERVICES MANAGEMENT
 
We reserve the right, but not the obligation, to: (1) monitor the Services for violations of these Legal Terms; (2) take appropriate legal action against anyone who, in our sole discretion, violates the law or these Legal Terms, including without limitation, reporting such user to law enforcement authorities; (3) in our sole discretion and without limitation, refuse, restrict access to, limit the availability of, or disable (to the extent technologically feasible) any of your Contributions or any portion thereof; (4) in our sole discretion and without limitation, notice, or liability, to remove from the Services or otherwise disable all files and content that are excessive in size or are in any way burdensome to our systems; and (5) otherwise manage the Services in a manner designed to protect our rights and property and to facilitate the proper functioning of the Services.
 
17. PRIVACY POLICY
    
We care about data privacy and security. By using the Services, you agree to be bound by our Privacy Policy, which is incorporated into these Legal Terms. Please be advised the Services are hosted in the UK. If you access the Services from any other region of the world with laws or other requirements governing personal data collection, use, or disclosure that differ from applicable laws in the UK, then through your continued use of the Services, you are transferring your data to the UK, and you expressly consent to have your data transferred to and processed in the UK.

Further, you consent to your data being transferred to and/or processed in other jurisdictions where Independent Registered Mediators or the moderators/assistants of the MediationX AI Mediator may be accessing the Services from.  

Further, you consent to your data being transferred to and/or processed in other jurisdictions where third-party AI technologies may be accessing the Services from.  

Further, you consent to your data being transferred to and/or processed in other jurisdictions where the Services are developed and/or maintained by the Company and/or its contractors.  

Further, we do not knowingly accept, request, or solicit information from children or knowingly market to children. 

We may collect personal information about you including, but not limited to, IP addresses, phone numbers, addresses, post codes, device identifiers, wireless identifiers, geographic identifiers etc. By using the Services you agree to have no objection to us collecting this data either from you or from third parties including wireless and broadband carriers.
         
18. Copyright
     
Notifications
     
We respect the intellectual property rights of others. If you believe that any material available on or through the Services infringes upon any copyright you own or control, please immediately notify us at legal@mediationx.com (a “Notification”). A copy of your Notification will be sent to the person who posted or stored the material addressed in the Notification. Please be advised that you may be held liable for damages if you make material misrepresentations in a Notification. 
     
All Notifications should include the following information: (1) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; (2) identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works on the Services are covered by the Notification, a representative list of such works on the Services; (3) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material; (4) information reasonably sufficient to permit us to contact the complaining party, such as an address, telephone number, and, if available, an email address at which the complaining party may be contacted; (5) a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and (6) a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed upon.
     
Counter Notification
     
If you believe your own copyrighted material has been removed from the Services as a result of a mistake or misidentification, you may submit a written counter notification to us via legal@mediationx.com (a “Counter Notification”). To be an effective Counter Notification, your Counter Notification must include substantially the following: (1) identification of the material that has been removed or disabled and the location at which the material appeared before it was removed or disabled; (2) a statement that you will accept service of process from the party that filed the Notification or the party's agent; (3) your name, address, and telephone number; (4) a statement under penalty of perjury that you have a good faith belief that the material in question was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled; and (5) your physical or electronic signature.
     
If you send us a valid, written Counter Notification meeting the requirements described above, we will restore your removed or disabled material, unless we first receive notice from the party filing the Notification informing us that such party has filed a court action to restrain you from engaging in infringing activity related to the material in question. Please note that if you materially misrepresent that the disabled or removed content was removed by mistake or misidentification, you may be liable for damages. 

19. TERM AND TERMINATION
 
These Legal Terms shall remain in full force and effect in perpetuity.

WITHOUT LIMITING ANY OTHER PROVISION OF THESE LEGAL TERMS, WE RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE LEGAL TERMS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY TERMINATE YOUR USE OR PARTICIPATION IN THE SERVICES OR DELETE YOUR ACCOUNT AND ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION. 
 
If we terminate or suspend your account for any reason, you are prohibited from registering and creating a new account under your name, a fake or borrowed name, or the name of any third party, even if you may be acting on behalf of the third party. In addition to terminating or suspending your account, we reserve the right to take appropriate legal action, including without limitation pursuing civil, criminal, and injunctive redress.
 
20. MODIFICATIONS AND INTERRUPTIONS
 
We reserve the right to change, modify, or remove the contents of the Services at any time or for any reason at our sole discretion without notice. However, we have no obligation to update any information on our Services. We also reserve the right to modify or discontinue all or part of the Services without notice at any time. We will not be liable to you or any third party for any modification, price change, suspension, or discontinuance of the Services. 
 
We cannot guarantee the Services will be available at all times. We may experience hardware, software, or other problems or need to perform maintenance related to the Services, resulting in interruptions, delays, or errors. We reserve the right to change, revise, update, suspend, discontinue, or otherwise modify the Services at any time or for any reason without notice to you. You agree that we have no liability whatsoever for any loss, damage, or inconvenience caused by your inability to access or use the Services during any downtime or discontinuance of the Services. Nothing in these Legal Terms will be construed to obligate us to maintain and support the Services or to supply any corrections, updates, or releases in connection therewith.
 
21. GOVERNING LAW
 
These Legal Terms and your use of the Services are governed by and construed in accordance with the laws of England and Wales applicable to agreements made and to be entirely performed within England and Wales, without regard to its conflict of law principles.
 
22. DISPUTE RESOLUTION
       
Informal Negotiations
To expedite resolution and control the cost of any dispute, controversy, or claim related to these Legal Terms (each a "Claim" and collectively, the “Claims”) brought by either you or us (individually, a “Party” and collectively, the “Parties”), the Parties agree to first attempt to negotiate any Claim (except those Claims expressly provided below) informally for at least 45 days before initiating arbitration. Such informal negotiations commence upon written notice from one Party to the other Party.

Binding Arbitration

If the Parties are unable to resolve a Claim through informal negotiations, the Claim (except those Claims expressly excluded below) will be finally and exclusively resolved through binding arbitration. 

The arbitration shall be commenced and conducted under the such rules as the Chartered Institute of Arbitrators deem fit and proper in the circumstances. The number of arbitrators shall be 1 and shall be chosen by the Chartered Institute of Arbitrators. Each party to a Claim shall be responsible for an equal share of arbitration fees.

The arbitration may be conducted in person, through the submission of documents, by phone, or online. The arbitrator will make a decision in writing, but need not provide a statement of reasons unless requested by either Party. The arbitrator must follow applicable law, and any award may be challenged if the arbitrator fails to do so. 

The arbitration will take place in London, England. Except as otherwise provided herein, the Parties may litigate in court to compel arbitration, stay proceedings pending arbitration, or to confirm, modify, vacate, or enter judgment on the award entered by the arbitrator.
 
If for any reason, a Claim proceeds in court rather than arbitration, the Claim shall be commenced in the courts located in London, England, and you hereby consent to, and waive all defenses of lack of personal jurisdiction, and forum non conveniens with respect to venue and jurisdiction in such courts. Application of the United Nations Convention on Contracts for the International Sale of Goods and the Uniform Computer Information Transaction Act (UCITA) is excluded from these Legal Terms.
     
Restrictions
     
The Parties agree that any arbitration shall be limited to the Claim between the Parties. To the full extent permitted by law, (a) no arbitration shall be joined with any other proceeding; (b) there is no right or authority for any Claim to be arbitrated on a class-action (or group-action) basis or to utilize class-action (or group-action) procedures; and (c) there is no right or authority for any Claim to be brought in a purported representative capacity on behalf of the general public or any other persons.
     
Exceptions to Informal Negotiations and Arbitration
     
The Parties agree that the following Claims are subject to the above provisions concerning informal negotiations and binding arbitration only at the discretion of the Company: (a) any Claims by the Company seeking to enforce or protect, or confirming the validity of, any of the intellectual property rights of the Company; (b) any Claim by the Company related to, or arising from, allegations of theft, piracy, invasion of privacy, or unauthorized use; and (c) any claim by the Company for injunctive relief.

If this provision is found to be illegal or unenforceable, then neither Party will elect to arbitrate any Claim falling within that portion of this provision found to be illegal or unenforceable and such Claim shall be decided by a court of competent jurisdiction within the courts listed for jurisdiction above, and you agree to submit to the jurisdiction of that court.
     
23. CORRECTIONS 

There may be information on the Services that contains typographical errors, inaccuracies, or omissions, including descriptions, pricing, availability, and various other information. We reserve the right to correct any errors, inaccuracies, or omissions and to change or update the information on the Services at any time, without prior notice.
 
24. DISCLAIMER
 
THE SERVICES ARE PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE SERVICES WILL BE AT YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICES’ CONTENT OR THE CONTENT OF ANY WEBSITES OR MOBILE APPLICATIONS LINKED TO THE SERVICES AND WE WILL ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE SERVICES BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE SERVICES, ANY HYPERLINKED WEBSITE, ANY THIRD PARTY CONTENT,,  OR ANY WEBSITE OR MOBILE APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING.

WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES (SAVE AS MAY BE SET OUT IN THE MEDIATIONX MEDIATION AGREEMENT).

 AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
 
25. LIMITATIONS OF LIABILITY
 
The Company does not limit its liability contrary to law. Subject to that:

IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT, BUSINESS LOSSES OR INTERRUPTIONS, LOST REVENUE, LOST OPPORTUNITIES, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR USE OF THE SERVICES, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR EVEN IF SUCH LOSSES OR DAMAGES COULD REASONABLY BE EXPECTED TO FLOW FROM A TORTIOUS/NEGLIGENT ACT OR BREACH OF CONTRACT.

NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, OUR LIABILITY TO YOU FOR ANY CAUSE WHATSOEVER AND REGARDLESS OF THE FORM OF THE ACTION, WILL AT ALL TIMES BE LIMITED, TO THE EXTENT PERMITTED BY LAW, TO THE LESSER OF:
 THE AMOUNT PAID, IF ANY, BY YOU TO US DURING THE 6 MONTH PERIOD PRIOR TO ANY CAUSE OF ACTION ARISING OR
 THE VALUE OF THE DISPUTE WHICH WAS THE SUBJECT MATTER OF THE MEDIATION (IF ANY, AS DISCLOSED AT THE TIME THE DISPUTE WAS FIRST BROUGHT TO THE ATTENTION OF THE COMPANY) OR
 £1000 IN TOTAL. 

Furthermore, all Independent Registered Mediators are legal professionals who are required to have professional indemnity insurance, and the Company will in any event bear no liability for matters subject to such insurance until an aggrieved party has pursued a claim against the Independent Registered Mediator(s) (and their insurers). 
  
26. INDEMNIFICATION
 
You agree to defend, indemnify, and hold us harmless, including our subsidiaries, affiliates, and all of our respective officers, agents, partners, and employees, from and against any loss, damage, liability, claim, or demand, including reasonable attorneys’ fees and expenses, made by any third party due to or arising out of: (1) your Contributions; (2) use of the Services; (3) breach of these Legal Terms; (4) any breach of your representations and warranties set forth in these Legal Terms; (5) your violation of the rights of a third party, including but not limited to intellectual property rights; or (6) any overt harmful act toward any other user of the Services with whom you connected via the Services. Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive defense and control of any matter for which you are required to indemnify us, and you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming aware of it.
  
27. USER DATA
 
We will maintain certain data that you transmit to the Services for the purpose of managing the performance of the Services, as well as data relating to your use of the Services. Although we perform regular routine backups of data, you are solely responsible for all data that you transmit or that relates to any activity you have undertaken using the Services. You agree that we shall have no liability to you for any loss or corruption of any such data, and you hereby waive any right of action against us arising from any such loss or corruption of such data.
  
28. ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
 
Visiting and using the Services, sending us emails, and completing online forms constitute electronic communications. You consent to receive, directly or indirectly from the Company, electronic communications (including WhatsApp messages, SMS messages, e-mails), and you agree that all agreements, notices, disclosures, and other communications we provide to you electronically, via email and on the Services, satisfy any legal requirement that such communication be in writing. YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE SERVICES. You hereby waive any rights or requirements under any statutes, regulations, rules, ordinances, or other laws in any jurisdiction which require an original signature or delivery or retention of non-electronic records, or to payments or the granting of credits by any means other than electronic means. 
 
29. MISCELLANEOUS
 
These Legal Terms and any policies or operating rules posted by us on the Services or in respect to the Services constitute the entire agreement and understanding between you and us. Our failure to exercise or enforce any right or provision of these Legal Terms shall not operate as a waiver of such right or provision. These Legal Terms operate to the fullest extent permissible by law. We may assign any or all of our rights and obligations to others at any time. We shall not be responsible or liable for any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control. If any provision or part of a provision of these Legal Terms is determined to be unlawful, void, or unenforceable, that provision or part of the provision is deemed severable from these Legal Terms and does not affect the validity and enforceability of any remaining provisions. There is no joint venture, partnership, employment, or agency relationship created between you and us as a result of these Legal Terms or use of the Services. You agree that these Legal Terms will not be construed against us by virtue of having drafted them. You hereby waive any and all defenses you may have based on the electronic form of these Legal Terms and the lack of signing by the parties hereto to execute these Legal Terms.

29. MEDIATIONX MEDIATION AGREEMENT

The MediationX Mediation Agreement is incorporated into these Legal Terms
 
30. CONTACT US
 
In order to resolve a complaint regarding the Services or to receive further information regarding use of the Services, please contact us at support@mediationx.com:
`
