import { React, Settings, AppImages } from '@/app'
import {
  View,
  CenterWrapper,
  Footer,
  Header,
  Image,
  ActivityIndicator,
} from '@/components'
import SEO, { SEOProps } from '../SEO'
import {
  ComponentVariants,
  PropsOf,
  StylesOf,
  useDefaultComponentStyle,
  useNestedStylesByKey,
} from '@codeleap/common'
import { Navigation, RoutePath, useUpdateLocale } from '@/utils'
import { PageComposition, PageStyles } from '../../app/stylesheets/Page'
import { useSearchParams } from '@codeleap/web'

export type PageProps = {
  showHeader?: boolean
  showFooter?: boolean
  showBackgroundImage?: boolean
  backgroundImageSource?: string
  backgroundImageProps?: Partial<PropsOf<typeof Image>>
  pageTitle?: string
  appendNameToPageTitle?: boolean
  centerContent?: boolean
  SEOProps?: Partial<SEOProps>
  className?: string
  contentProps?: Partial<PropsOf<typeof CenterWrapper>>
  styles?: StylesOf<PageComposition>
  style?: React.CSSProperties
  children: React.ReactNode
  authWall?: boolean
  redirectPath?: RoutePath
  loading?: boolean
} & ComponentVariants<typeof PageStyles>

const defaultProps: Partial<PageProps> = {
  showHeader: true,
  showFooter: true,
  appendNameToPageTitle: false,
  centerContent: true,
  showBackgroundImage: false,
  backgroundImageSource: AppImages.LogoImage,
  SEOProps: {},
  redirectPath: 'Disputes.List',
  loading: false,
}

export const Page = (props: PageProps) => {
  const allProps = {
    ...Page.defaultProps,
    ...props,
  }

  const {
    showHeader,
    showFooter,
    pageTitle,
    appendNameToPageTitle,
    centerContent,
    showBackgroundImage,
    backgroundImageSource,
    backgroundImageProps,
    SEOProps,
    className,
    children,
    variants,
    responsiveVariants,
    styles,
    contentProps,
    style,
    authWall,
    redirectPath,
    ...rest
  } = allProps

  const variantStyles = useDefaultComponentStyle<'u:Page', typeof PageStyles>(
    'u:Page',
    {
      responsiveVariants,
      rootElement: 'wrapper',
      styles,
      variants,
    }
  )

  const [params] = useSearchParams()

  const contentStyles = useNestedStylesByKey('content', variantStyles)
  const footerStyles = useNestedStylesByKey('footer', variantStyles)

  useUpdateLocale()

  const appendedTitle = appendNameToPageTitle ? ` | ${Settings.AppName}` : ''
  const SEOTitle = pageTitle && `${pageTitle} ${appendedTitle}`

  const innerWrapperStyles = React.useMemo(
    () => [
      variantStyles.innerWrapper,
      variantStyles['innerWrapper:backgroundImage'],
    ],
    []
  )

  const InnerWrapper = centerContent ? CenterWrapper : React.Fragment
  const innerWrapperProps = centerContent
    ? { styles: contentStyles, ...contentProps }
    : {}

  if (authWall) {
    Navigation.navigate(redirectPath, { params })
  }

  return (
    <View css={[variantStyles.wrapper, style]} className={className} {...rest}>
      <SEO title={SEOTitle} doNotTrack {...SEOProps} />

      {showBackgroundImage ? (
        <Image
          source={backgroundImageSource}
          style={variantStyles.backgroundImage}
          {...backgroundImageProps}
        />
      ) : null}

      <View css={innerWrapperStyles}>
        {showHeader ? <Header /> : null}

        <InnerWrapper {...innerWrapperProps}>
          {props?.loading ? (
            <ActivityIndicator variants={['flex', 'center', 'fullWidth']} />
          ) : (
            children
          )}
        </InnerWrapper>
        {showFooter ? <Footer styles={footerStyles} /> : null}
      </View>
    </View>
  )
}

Page.defaultProps = defaultProps
